import Vue from 'vue';
import VueRouter from 'vue-router';
import { isEqual } from 'lodash';

// These pages are needed right away, so import them when first visited
// The remaining routes can be lazy-loaded as needed
import Login from '@/views/pages/Login.vue';
import PageNotFound from '@/views/pages/PageNotFound.vue';
import store from './store/index';
import { CoiError } from './frontEndErrorHandler';

Vue.use(VueRouter);

// Define custom routes to use
const routes = [
  {
    path: '/',
    redirect: { name: 'Login' }, // Auto-redirect to login from root URL
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login' },
  },
  {
    path: '/logout',
    name: 'Logout',
  },
  {
    path: '/register',
    name: 'Registration',
    component: () => import('@/views/pages/Registration.vue'),
    meta: { title: 'Registration' },
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: () => import('@/views/pages/ForgotPassword.vue'),
    meta: { title: 'Forgot Password' },
  },
  {
    path: '/two-factor-registration',
    name: 'TwoFactorRegistration',
    component: () => import('@/views/pages/twoFactorRegistration.vue'),
    meta: { title: 'Two-factor Registration' },
  },
  {
    path: '/inbox',
    name: 'SearchInbox',
    component: () => import('@/views/pages/search-pages/Search-Inbox.vue'),
    meta: {
      title: 'Inbox',
      requiresAuth: true,
    },
  },
  {
    path: '/disclosures',
    name: 'SearchDisclosures',
    component: () => import('@/views/pages/search-pages/Search-Disclosures.vue'),
    meta: {
      title: 'Disclosures',
      requiresAuth: true,
    },
  },
  {
    path: '/projects',
    name: 'SearchProjects',
    component: () => import('@/views/pages/search-pages/Search-Projects.vue'),
    meta: {
      title: 'Projects',
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'SearchUsers',
    component: () => import('@/views/pages/search-pages/Search-Users.vue'),
    meta: {
      title: 'Users',
      requiresAuth: true,
    },
  },
  {
    path: '/userErrors',
    name: 'SearchUserErrors',
    component: () => import('@/views/pages/search-pages/Search-UserErrors.vue'),
    meta: {
      title: 'User Sync Errors',
      requiresAuth: true,
    },
  },
  {
    path: '/df/:disclosureId',
    name: 'DisclosureForm',
    component: () => import('@/views/pages/disclosure-forms/.Disc-FormParent.vue'),
    meta: {
      title: 'OHSU Disclosure Form',
      requiresAuth: true,
    },
  },
  {
    path: '/legacysnapshot/latestForUser/:userId',
    name: 'LatestLegacyDisclosureSnapshot',
    component: () => import('@/views/pages/LegacySnapshot.vue'),
    meta: {
      title: 'OHSU Legacy Disclosure Snapshot',
      requiresAuth: true,
    },
  },
  {
    path: '/profile/:userId',
    name: 'UserProfile',
    component: () => import('@/views/pages/user-profile-forms/.UserProfile-FormParent.vue'),
    meta: {
      title: 'User Profile',
      requiresAuth: true,
    },
  },
  {
    path: '/page-not-found',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: { title: 'Page Not Found' },
  },
  // Added a catch-all to redirect to page not found if the user has a link that doesn't match anything above
  {
    path: '*',
    redirect: { name: 'PageNotFound' },
  },
];

// Instantiate new router object to use
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from && to.name === from.name && isEqual(to.params, from.params)) {
      return null;
    }
    return savedPosition || { x: 0, y: 0 };
  },
});

// This callback runs before every route change, including on page load.
router.beforeEach(async (to, from, next) => {
  Vue.$log.debug('arrrived at router callback');
  try {
    // Get session info for user if it exists
    await store.dispatch('updateSession');

    // If this is the logout page, trigger logout
    if (to.name === 'Logout') {
      // If there is an active session, call logout action
      if (store.state.session.loggedIn === true) {
        const logoutData = await store.dispatch('logout');
        Vue.$log.debug(`Data returned from logout: ${JSON.stringify(logoutData)}`);
      }
      return next({ name: 'Login' });
    }

    // If this is the login page and user is already authenticaed, redirect to user inbox
    if (to.name === 'Login' && store.state.session.loggedIn === true) {
      return next({ name: 'SearchInbox' });
    }

    // If auth required on the destination page, check for valid session, and redirect to login
    // page if session not present. Save the users original target URL in a query parameter so
    // they can be directed to their intended destination after login.
    if (to.matched.some((route) => route.meta.requiresAuth)
      && store.state.session.loggedIn !== true) {
      Vue.$log.debug('redirect to login page');
      return next({
        name: 'Login',
        query: { redirectFrom: to.fullPath },
      });
    }

    // If a route with a title was found, set the document (page) title to that value.
    const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
    if (nearestWithTitle) {
      const nonOhsuDisclosure = to.name === 'DisclosureForm' && store.state.session.isOhsuUser === false;
      document.title = `${nonOhsuDisclosure ? 'Non ' : ''}${nearestWithTitle.meta.title} - eCOI`;
    }

    return next();
  } catch (e) {
    throw new CoiError(e, 'vueRouter.js (router.beforeEach)');
  }
});

export default router;
