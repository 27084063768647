// Vuex module for managing user authentication sessions
import Vue from 'vue';
import { cloneDeep, defaultsDeep, isEmpty } from 'lodash';
import { CoiError } from '../frontEndErrorHandler';
import { genericGet, genericPost } from '../controllers/common.controller';

// Set default state for session data for easy resetting later
const getDefaultSessionState = () => {
  const defaultSessionState = {
    loggedIn: null,
    lastMainPage: JSON.parse(sessionStorage.getItem('coiLastMainPage') || '{ "name": "SearchInbox" }'),
    id: '',
    userName: '',
    fullName: '',
    preferredName: '',
    disclosureFormSet: '',
    isOhsuUser: false,
    isSamlUser: false,
    isCoiAdmin: false,
    isCoiAnalyst: false,
    isCoiSuperReader: false,
    isCoiReader: false,
    isCoiOfficeRole: false,
    isDisabled: false,
    isReviewer: false,
    hasHrRole: false,
    hasDisclosureInProgress: false,
    nextDueDate: null,
    nextPwChangeDate: null,
    isContact: false,
  };
  // Needed to use Lodash deep clone to deal with deep nested object
  return cloneDeep(defaultSessionState);
};

export default {
  state: getDefaultSessionState(),
  getters: {
    coiAdminAnalyst: (state) => state.isCoiAdmin || state.isCoiAnalyst,
  },
  mutations: {
    setLoggedIn(state, loggedInVal) { state.loggedIn = loggedInVal; },
    setLastPage(state, routeObj) { state.lastMainPage = routeObj; },
    setSessionData(state, sessionData) {
      // Use object.assign to avoid losing state observer/reactivity
      Object.assign(state, sessionData);
    },
    resetSessionData(state) {
      // Use object.assign to avoid losing state observer/reactivity
      Object.assign(state, getDefaultSessionState());
    },
  },
  actions: {
    // Simple action to set the last main site page you were on prior to opening a form or other sub-page.
    async setLastPage({ commit }, routeObj) {
      try {
        if (!routeObj || isEmpty(routeObj) || !routeObj.name) {
          throw new CoiError('Route object missing or not in expected format');
        }
        commit('setLastPage', routeObj);

        // Also save in user's session storage so it will survive a hard page refresh
        sessionStorage.setItem('coiLastMainPage', JSON.stringify(routeObj));
      } catch (e) {
        throw new CoiError(e, 'session.js (setLastPage)');
      }
    },

    // If user session is unknown (loggedIn is null, rather than true or false), get new session
    // Details from the express server and update Vuex
    async updateSession({ state, commit, dispatch }) {
      try {
        if (state.loggedIn === null) {
          Vue.$log.debug('Logged in bool null, getting session info from server');

          const sessData = await genericGet('session');
          if (!sessData) {
            // If no data returned, user is not authenticated, set data to null and bool to false
            Vue.$log.debug('No active session for user, setting logged in value to false');
            commit('resetSessionData');
            commit('setLoggedIn', false);
          } else {
            // Save user session data in Vuex if active session data was returned that was not
            // already in Vuex. This can happen if a user does a hard refresh and the Vuex cache
            // is reset. In this case, we need to repopulate the Vuex data accurately.
            Vue.$log.debug('Active session for user, updating bool and session data');
            await dispatch('setSessionData', sessData);
            commit('setLoggedIn', true);
          }
        }
      } catch (e) {
        // Null out session data in Vuex if there is a session issue
        commit('resetSessionData');
        throw new CoiError(e, 'session.js (updateSession)');
      }
    },

    // When certain user data is updated (username, roles and such) it may be necessary to refresh
    // the user session from the database
    async refreshSession({ commit, dispatch }) {
      try {
        await genericGet('sessionRefr');
        const sessData = await genericGet('session');
        if (!sessData) {
          // If no data returned, user is not authenticated, set data to null and bool to false
          Vue.$log.debug('No active session for user, setting logged in value to false');
          commit('resetSessionData');
          commit('setLoggedIn', false);
        } else {
          // Save user session data in Vuex if active session data was returned that was not
          // already in Vuex. This can happen if a user does a hard refresh and the Vuex cache
          // is reset. In this case, we need to repopulate the Vuex data accurately.
          Vue.$log.debug('Active session for user, updating bool and session data');
          await dispatch('setSessionData', sessData);
          commit('setLoggedIn', true);
        }
      } catch (e) {
        throw new CoiError(e, 'session.js (refreshSession)');
      }
    },

    // Handle user session login (cookie generation done on express server)
    async tfaLogin({ commit, dispatch }, data) {
      try {
        // const loginData = await genericPost('login', { username: data.userName, password: data.password, code: data.totp });
        const loginData = await genericPost('totpLogin', { username: data.username, password: data.password, code: data.code });
        // Save user session data in Vuex
        if (loginData && !loginData.message) await dispatch('setSessionData', loginData);
        return loginData;
      } catch (e) {
        // Null out session data in Vuex if there is a login issue
        commit('resetSessionData');
        throw new CoiError(e, 'session.js (login)');
      }
    },

    // Handle user session login (cookie generation done on express server)
    async ldapLogin({ commit, dispatch }, data) {
      try {
        const loginData = await genericPost('ldapLogin', { username: data.username, password: data.password });
        // Save user session data in Vuex
        if (loginData && !loginData.message) await dispatch('setSessionData', loginData);
        return loginData;
      } catch (e) {
        // Null out session data in Vuex if there is a login issue
        commit('resetSessionData');
        throw new CoiError(e, 'session.js (login)');
      }
    },

    async testUserLogin({ commit, dispatch }, data) {
      try {
        const loginData = await genericPost('testUserLogin', { username: data.username, password: data.password });
        // Save user session data in Vuex
        if (loginData && !loginData.message) await dispatch('setSessionData', loginData);
        return loginData;
      } catch (e) {
        // Null out session data in Vuex if there is a login issue
        commit('resetSessionData');
        throw new CoiError(e, 'session.js (testUserLogin)');
      }
    },

    // Handle user logout and cookie delete
    async logout({ commit }) {
      try {
        await genericGet('logout', {}, 204);

        // Clear out Vuex session data
        commit('resetSessionData');
      } catch (e) {
        throw new CoiError(e, 'session.js (logout)');
      }
    },

    // Process to set session data and default values if all data is not specified
    async setSessionData({ commit }, sessionData) {
      try {
        // Check to make sure session data added has correct properties
        if (!sessionData || !sessionData.id) {
          throw new CoiError('Session data not in expected format', 'session.js (setSessionData)', sessionData);
        }

        // Get the default session object to start with as template.
        // Allows session object to be sent in with only the fields that need to be customized
        const defaultSessionData = getDefaultSessionState();

        // Use lodash defaultsdeep function to ensure session object has all default fields it needs
        // Even if they were not specified in passed in object
        const newSessionData = defaultsDeep({}, sessionData, defaultSessionData);
        commit('setSessionData', newSessionData);
      } catch (e) {
        throw new CoiError(e, 'session.js (setSessionData)');
      }
    },
  },
};
