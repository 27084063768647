<template>
  <b-container fluid id="pagenotfound-page">
    <b-row class="justify-content-center flex-grow-1">
      <b-col cols="12" md="8" xl="6" id="pagenotfound-col" align-self="center" >
        <b-card footer-tag="footer" id="pagenotfound-card">
          <h1 class="mt-3 mb-3">404 - Page Not Found</h1>
          <p v-if="this.$route.params.errorMsg">{{this.$route.params.errorMsg}}</p>
          <p v-else>The page you attempted to navigate to was not found.</p>
          <p>If you got here from another link within COI, or something else is broken you can reach out
            to the COI office for help:</p>
          <COIOfficeContact />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import COIOfficeContact from '@/views/components/COIOfficeContact.vue';

export default {
  name: 'PageNotFound',
  components: {
    COIOfficeContact,
  },
};
</script>;

<style lang="scss">
  #pagenotfound-page {
    background-color: $gray-200;
  }
</style>
