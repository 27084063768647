<template>
  <ValidationProvider
    v-if="!isHiddenComp"
    :fieldId="thisFieldId"
    :fieldName="fieldName || ''"
    :fieldLabel="fieldLabel"
    :fieldDescription="fieldDescription"
    :valFunction="valFunction"
    :valRules="(isReadOnlyComp === true ? undefined : valRules)"
    :valMode="valMode"
    :valOnLoad="valOnLoad"
    :renderGroup="renderGroup"
    v-slot="{ getValidationState }"
    :valProviderAttrs="valProviderAttrs"
    :formGroupAttrs="formGroupAttrs"
  >
    <b-input-group v-show="displayInputControl">
      <b-input-group-prepend v-if="!!$slots['prepend']">
        <slot name="prepend"></slot>
      </b-input-group-prepend>
      <b-form-input
        v-if="!isReadOnlyComp"
        :id="thisFieldId"
        :name="htmlFieldName"
        :type="inputType"
        :state="getValidationState"
        :readonly="isReadOnlyComp"
        :placeholder="fieldPlaceholder"
        v-model="currentValue"
        v-bind="inputAttrs"
        @focus="handleFocus"
      />
      <div v-else :id="thisFieldId" :name="htmlFieldName" class='disabled-input d-flex flex-fill'>
        {{ renderCurrentValue }}
      </div>
      <b-input-group-append v-if="!!$slots['append']">
        <slot name="append"></slot>
      </b-input-group-append>
    </b-input-group>
  </ValidationProvider>
</template>

<script>
import ValidationProvider from '@/views/components/form-controls/.ValidationProvider.vue';
import { CoiError } from '../../../frontEndErrorHandler';

export default {
  name: 'InputField',
  components: {
    ValidationProvider,
  },
  // Inject the form/page IDs from the parent objects, so it doesn't need to be passed in
  inject: {
    formIdParent: { default: null },
    pageIdParent: { default: null },
  },
  props: {
    value: { type: [String, Number] },
    fieldId: { type: String, required: true },
    fieldName: { type: String, required: true }, // Displayed in validation messages for field, also used as html name attribute (lower cased and spaces removed)
    formId: { type: String, default() { return this.formIdParent; } },
    pageId: { type: String, default() { return this.pageIdParent; } },
    vuexDataPath: { type: String },
    fieldLabel: { type: String, default: '' },
    fieldDescription: { type: String, default: '' },
    fieldPlaceholder: { type: String, default: null },
    inputType: { type: String, default: 'text' },
    isReadOnly: { type: Boolean, default: null },
    isHidden: { type: Boolean, default: false },
    renderGroup: { type: Boolean, default: true },
    displayInputControl: { type: Boolean, default: true }, // Hide input control, but still display validation messages. Used for computed properties.
    valFunction: { type: String, default: undefined },
    valRules: { type: [String, Object], default: undefined },
    valMode: { type: String, default: undefined },
    valOnLoad: { type: Boolean, default: undefined },
    // Can pass any extra bootstrap or vee validate attributes directly to their base components using objects below
    inputAttrs: { type: Object, default: null },
    formGroupAttrs: { type: Object, default: null },
    valProviderAttrs: { type: Object, default: null },
    // fieldSelectTrigger: { type: Boolean, default: false },
  },
  data() {
    return {
      hiddenValue: null, // Cache the current value if field is hidden, so the value can be re-set if un-hidden prior to page save
      formSaveHandler: null,
    };
  },
  computed: {
    thisFieldId() {
      if (this.pageId) return `field-${this.fieldId}-${this.pageId}`;
      if (this.formId) return `field-${this.fieldId}-${this.formId}`;
      return `field-${this.fieldId}`;
    },
    htmlFieldName() { return this.fieldName?.replace(/ /g, '-')?.toLowerCase() || ''; },
    isReadOnlyComp() {
      // isReadOnly should default to vuex state for the whole form/page, but each field can be overriden
      return this.$store.getters.pageIsReadOnly(this.pageId) || this.isReadOnly;
    },
    isHiddenComp() {
      // isHidden should return true if the form page it is on is hidden, or if this question is hidden
      return (this.isHidden === true || this.$store.getters.pageObj(this.pageId)?.isHidden === true);
    },
    currentValue: {
      get() {
        if (this.vuexDataPath) return this.$store.getters.getField(`forms.${this.formId}.localData.${this.vuexDataPath}`);
        return this.value;
      },
      set(value) {
        if (!this.isReadOnlyComp) {
          if (this.vuexDataPath) this.$store.commit('updateField', { path: `forms.${this.formId}.localData.${this.vuexDataPath}`, value });
          this.$emit('input', value);
        }
      },
    },
    renderCurrentValue() {
      // If input type is a password, render dots instead of actual value
      return (this.inputType === 'password' ? Array(this.currentValue.length + 1).join('•') : this.currentValue);
    },
  },
  watch: {
    // Watch the isHidden boolean, update data state automatically on hide/show
    isHiddenComp(isHiddenBool) {
      this.$log.debug(`isHidden watcher triggered on ${this.thisFieldId}: ${isHiddenBool}`);
      // Trigger updates to page object only after it has been initialized
      if (!this.isReadOnlyComp && (this.vuexDataPath ? this.$store.getters.formDataLoaded(this.formId) : true)) {
        if (isHiddenBool) {
          // Set input field to empty string on hidden, and cache the current value just in case field is un-hidden prior to save
          this.hiddenValue = this.currentValue;
          this.currentValue = null;
        } else {
          // Set input field to the value preserved in hiddenValue if shown
          this.currentValue = this.hiddenValue;
          this.hiddenValue = null;
        }
      }
    },
  },
  methods: {
    handleFocus(event) {
      if (this.inputAttrs?.fieldSelectTrigger) {
        event.target.select();
      }
    },
  },
  created() {
    try {
      this.$log.debug(`Creating new input component with id ${this.thisFieldId}`);

      // Make sure a form ID is correctly pulled if we are using a vuex data path
      if (this.vuexDataPath && !this.formId) {
        throw new CoiError('Must be a form ID defined if we are pulling the field value from Vuex', 'InputField.vue (created)');
      }

      // Subscribe to form save, so hidden values can be erased
      this.formSaveHandler = (formId) => {
        this.$log.debug(`Form save heard by ${this.thisFieldId}, ${formId}`);
        // Only erase hidden values if save action is called for the current form
        if (formId === this.formId) {
          this.$log.debug(`Resetting hidden value on radio field ${this.thisFieldId} after form save`);
          this.hiddenValue = null;
        }
      };
      this.$root.$on('form-saved', this.formSaveHandler);
    } catch (e) {
      throw new CoiError(e, 'InputField.vue (created)');
    }
  },
  beforeDestroy() {
    try {
      this.$root.$off('form-saved', this.formSaveHandler);
    } catch (e) {
      throw new CoiError(e, 'InputField.vue (beforeDestroy)');
    }
  },
};
</script>

<style lang="scss">
.disabled-input {
  border: 2px solid $gray-400;
  background-color: $gray-100;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  opacity: 0.7;
  min-height: 2.5rem;
  border-radius: $border-radius;
}
</style>
