<template functional>
  <!-- eslint-disable max-len -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 173.693 297.613"
    role="img"
    aria-labelledby="ohsu-logo-white"
    id="ohsu-logo-icon"
  >
    <title>OHSU flame logo in white</title>
    <g fill="#fff">
      <path d="M103.778 240.634c-32.982 0-64.82 3.56-90.538 9.455l1.117 3.182c25.004-4.509 54.279-7.338 85.446-6.32 20.399.671 39.852 3.33 57.768 6.32l3.366-9.512c-17.802-2.33-37.053-3.125-57.16-3.125M43.457 62.519c73.743-45.254 66.855-50.554 64.076-54.634l.207-.14c5.38 4.588 18.799 24.092-41.512 61.59-54.8 34.074-58.325 55.269-33.883 77.162 6.188 5.543 8.168 6.31 18.348 12.464l-7.869 10.218c-11.928-7.544-90.468-48.847.633-106.66"></path>
      <path d="M97.18 131.045C24.99 85.76 31.32 81.34 34.06 77.325l-.205-.142c-5.302 4.512-17.895 23.728 41.523 60.604 57.176 35.487 51.158 57.84 30.081 91.052.02.223-.277.396-.262.573 28.843-19.636 51.298-61.16-8.018-98.367"></path>
      <path d="M55.845 143.005C46.83 122.823 50.48 110.417 92.18 84.72 156.567 45.027 118.67 8.456 108.423.133l.199-.126c11.955 6.704 61.342 49.981-5.892 92.144-30.843 19.365-49.687 30.685-46.885 50.854m57.059 36.208c-37.047 22.848-50.847 41.251-40.037 55.98-3.17-20.297 25.566-29.441 55.096-48.14 88.186-55.837 24.664-106.508 7.892-114.857l-.279.157c14.41 10.399 59.505 55.972-22.672 106.86"></path>
      <path d="M101.178 225.295l-.012-.213c9.001-20.175-13.441-43.334-55.131-69.035-64.41-39.699-24.09-77.511-13.838-85.834l-2.6 1.914c-14.343 10.753-61.789 50.041 5.451 92.208 30.85 19.363 68.915 40.801 66.13 60.97"></path>
      <path d="M78.493 135.837c68.04-45.252 60.822-52.029 58.268-56.126l.203-.131c4.959 4.581 19.645 24.14-36.003 61.628-64.484 43.467-64.297 58.56-30.698 89.145l2.623 4.916c-14.301-7.714-78.612-43.28 5.68-99.358m-43.42 132.114c-6.125 0-10.57 5.137-10.57 11.707 0 6.57 4.445 11.707 10.57 11.707 6.076 0 10.522-5.137 10.522-11.707 0-6.57-4.446-11.707-10.522-11.707m0 29.588c-10.275 0-18.13-8.003-18.13-17.881 0-9.88 7.855-17.883 18.13-17.883 10.225 0 18.08 8.002 18.08 17.883 0 9.878-7.855 17.88-18.08 17.88m45.957-.591v-14.819h-14.67v14.82h-7.065v-34.58h7.064v13.782h14.671v-13.781h7.064v34.578m20.21-29.145c-2.37 0-4.496 1.186-4.496 3.458 0 2.668 3.26 3.408 5.928 4.348 4.89 1.678 10.768 3.95 10.768 11.213 0 7.508-7.063 10.719-13.09 10.719-5.334 0-9.483-2.026-13.238-5.779l4.693-4.596c2.815 2.719 4.988 4.348 8.595 4.348 2.765 0 5.483-1.333 5.483-4.397 0-2.963-3.162-4.1-6.67-5.385-4.494-1.678-9.533-3.654-9.533-10.274 0-6.57 5.582-9.683 11.312-9.683 4.446 0 7.953 1.435 11.461 4.102l-4.05 5.038c-1.927-1.63-4.496-3.112-7.163-3.112m45.933 12.844c0 10.867-4.15 16.893-14.029 16.893-9.682 0-13.833-6.026-13.833-16.893v-18.277h7.064v19.215c0 6.62 2.668 9.78 6.868 9.78 4.197 0 6.865-3.16 6.865-9.78v-19.215h7.064"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OHSULogo',
};
</script>
