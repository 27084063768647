<template>
  <div id="site-error">
    <b-modal
      id="site-error-popup"
      modal-class="error-modal"
      header-text-variant="danger"
      title="Site Error"
      size="xl"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      v-model="hasErrors"
      :static="testMode"
    >
      <p>
        {{ lastError }}<br>
        Date: {{ (new Date()) }}<br>
        Environment: {{ $store.state.env.name }}
      </p>
      <b-table-simple id="error-stack-table" hover small responsive variant="danger" style="border: 1px solid #ec8c8f">
        <b-thead>
          <b-tr variant="danger">
            <b-th>Order</b-th>
            <b-th>Error Message</b-th>
            <b-th>Location</b-th>
            <b-th>Data</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="error in this.coiErrorStack" :key="error.id">
            <b-td :id="`${error.id}-err-id`">{{ error.id }}</b-td>
            <b-td :id="`${error.id}-err-msg`"><small>{{ error.errorMsg }}</small></b-td>
            <b-td :id="`${error.id}-err-location`"><small>{{ error.location }}</small></b-td>
            <b-td :id="`${error.id}-err-details`">
              <b-badge
                href="#"
                :id="'error-data-view-link' + error.id"
                class="error-details-btn"
                pill
                variant="secondary"
                v-show="error.data"
                @click="showData(error.data)"
              >
                <fa-icon icon="search" class="mr-1" /> Error Data
              </b-badge>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-button
        id="error-dismiss-btn"
        class="float-right"
        variant="danger"
        @click="resetError();"
      >
        Dismiss
      </b-button>
    </b-modal>
    <b-modal
      id="site-error-details"
      title="Error Details"
      size="lg"
      scrollable
      ok-only
      ok-title="Close"
      :static="testMode"
    >
      <vue-json-pretty :data="renderErrorData(this.errorDetails)"> </vue-json-pretty>
    </b-modal>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'SiteError',
  components: {
    VueJsonPretty,
  },
  props: {
    testMode: { type: Boolean, default: false },
  },
  data() {
    return {
      coiErrorStack: [],
      errorDetails: '',
    };
  },
  computed: {
    hasErrors() { return this.coiErrorStack.length > 0; },
    lastError() {
      return (this.coiErrorStack.length > 0) ? (this.coiErrorStack[this.coiErrorStack.length - 1].errorMsg || '') : '';
    },
  },
  mounted() {
    // reset error stack on refresh
    this.resetError();

    // listen on error stack for changes
    window.addEventListener('coi-error-thrown', () => {
      this.coiErrorStack = JSON.parse(sessionStorage.getItem('coiErrorStack') || '[]');
    });
  },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
    showData(errorData) {
      this.errorDetails = errorData;
      this.$bvModal.show('site-error-details');
    },
    renderErrorData(data) {
      if (data instanceof Error) {
        return `${data.name} - ${data.message}`;
      }
      return data;
    },
    resetError() {
      sessionStorage.setItem('coiErrorStack', '[]');
      this.coiErrorStack = [];
      // Loading may have been partially complete, make sure counts are reset
      this.$store.commit('resetLoading');
    },
  },
};
</script>

<style lang="scss">

  .error-modal {
    .modal-content {
      border-color: lighten($danger, 30);

      .modal-header, .modal-footer {
        background-color: lighten($danger, 30);
        border-color: lighten($danger, 30);
        z-index: 10000000000;
        h5 {
          color: darken($danger, 10) !important;
        }
      }

      #error-details-wrapper td {
        font-size: 75%;
      }

      .error-details-btn {
        border-radius: 0.25rem;
      }
    }
    #error-details-wrapper {
      border: 1px solid lighten($danger, 30);
    }
  }
</style>
