/* eslint-disable import/prefer-default-export */
/* eslint-disable max-classes-per-file */
import { isEmpty } from 'lodash';
/**
 * Set of helper functions which perform various types of Javascript object opeartions common accross the COI application
 * Ideally, any object defaulting or manipulation that is done more than once should be refactored to this file.
 */

/**
 * Internal helper class which wraps base error object, and handles all needed operations there
 */
class DomainError extends Error {
  constructor(message) {
    super(message); // Trigger base Error class
    // Ensure the name of this error is the same as the class name
    this.name = this.constructor.name;
    // This clips the constructor invocation from the stack trace.
    // It's not absolutely essential, but it does make the stack trace a little nicer.
    // Removing due to compatability issues
    // global.Error.captureStackTrace(this, this.constructor);
  }
}

/**
 * Custom error handler for the COI applicaiton, which takes additional parameters allowing us to specify
 * error details and associated data object. Also handles pushing new objects to the Vuex error stack so it
 * can be displayed in the UI
 */
export class CoiError extends DomainError {
  /**
   * Error class constructor
   * @param {Object, String} error - Can either be a string error message, or a previously caught error object
   * @param {String} location - Optional - Location the error was thrown. Optional, but encouraged for easier debugging
   * @param {Object} customErrorData - Optional - Any additional helper data to make debugging the error easier.
   */
  constructor(error, location = null, customErrorData = null) {
    if (error instanceof Error) {
      super(error.message); // Trigger base Error class
      this.errData = customErrorData || error.errData;
      this.message = error.message;
    } else {
      // If a basic string was passed in for the error, just create a basic error object with the message set to the string
      super(error);
      this.message = error;
      this.errData = customErrorData;
    }
    this.location = location || null;
    if (isEmpty(this.errData)) this.errData = null;

    // eslint-disable-next-line no-console
    console.error(`${this.location} - ${this.message}`);

    // Had to use base sessionStorage rather than Vuex to avoid circular import problems among libraries
    // Using sessionStorage instead of localStorage because we don't need the error stack to persist on page refresh
    const errorStack = JSON.parse(sessionStorage.getItem('coiErrorStack') || '[]');
    errorStack.push({
      location: this.location || 'Location Unknown',
      errorMsg: this.message || 'Unknown Error',
      data: this.errData || null,
      id: errorStack.length + 1,
    });
    sessionStorage.setItem('coiErrorStack', JSON.stringify(errorStack));

    // sessionStorage is not reactive, so use an event to indicate when a new error item is added to the stack
    window.dispatchEvent(new CustomEvent('coi-error-thrown'));
  }
}
