import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueLogger from 'vuejs-logger';
import './assets/icons/fontawesome_icons';
import './assets/styles/custom.scss';
import { detect } from 'detect-browser';
import App from './App.vue';
import router from './vueRouter';
import store from './store/index';
import { CoiError } from './frontEndErrorHandler';

Vue.config.productionTip = false;
// eslint-disable-next-line func-names
Vue.config.errorHandler = function (err, vm, info) {
  Vue.$log.error('Global Vue Error Handler', err, info);
  throw new CoiError(err, 'main.js (Global Error Handler)');
};

// Detect browser or assign a dummy object if running in headless mode
store.commit('setEnvironment', (detect() || { name: 'headless' }).name);

const loggerOptions = {
  isEnabled: true,
  logLevel: process.env.NODE_ENV !== 'development' ? 'info' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

Vue.use(BootstrapVue, {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
});
Vue.use(VueLogger, loggerOptions);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
