var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"pwcobserver",attrs:{"disabled":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{attrs:{"id":"changepw-form"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return passes(_vm.changePassword)}}},[_c('InputField',{attrs:{"fieldId":"changepw-current-pw","fieldName":"Current Password","fieldLabel":"Current Password","inputType":"password","valMode":"eager","isReadOnly":!!_vm.currentPwProp ? true : null,"valRules":{ required: !_vm.currentPwProp },"inputAttrs":{ maxlength: '128' }},model:{value:(_vm.currentPw),callback:function ($$v) {_vm.currentPw=$$v},expression:"currentPw"}}),_c('InputField',{attrs:{"fieldId":"changepw-new-pw","fieldName":"New Password","fieldLabel":"New Password","inputType":"password","valMode":"eager","valRules":{
        required: true, min: 8, max: 36, hasupperlower: true, hasnumber: true, hassymbol: true
      },"inputAttrs":{ maxlength: '36' }},model:{value:(_vm.newPw),callback:function ($$v) {_vm.newPw=$$v},expression:"newPw"}}),_c('InputField',{attrs:{"fieldId":"changepw-new-pwc","fieldName":"Confirm Password","fieldLabel":"Confirm New Password","inputType":"password","valMode":"eager","valRules":{
        required: true,
        matchfield: {
          target: _vm.newPw,
          messageStr: 'Must match Password field above',
        },
      },"inputAttrs":{ maxlength: '36' }},model:{value:(_vm.newPwc),callback:function ($$v) {_vm.newPwc=$$v},expression:"newPwc"}}),_c('b-alert',{staticClass:"my-3",attrs:{"variant":"danger","id":"changepw-errors-alert","dismissible":""},model:{value:(_vm.showChangeError),callback:function ($$v) {_vm.showChangeError=$$v},expression:"showChangeError"}},[(_vm.changePwResp && _vm.changePwResp.cpwStatus === 'current-pw-incorrect')?[_vm._v(" The current password entered is incorrect, please try again. ")]:_vm._e(),(_vm.changePwResp && _vm.changePwResp.cpwStatus === 'new-pw-same')?[_vm._v(" The new password must be different from the current password, please try again. ")]:_vm._e(),(_vm.changePwResp && _vm.changePwResp.cpwStatus === 'new-pw-already-used')?[_vm._v(" The new password entered has been used previously. Please select a different password. ")]:_vm._e()],2),_c('div',{staticClass:"clearfix"}),_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","variant":_vm.changePwText === 'Password Changed!' ? 'success' : 'primary',"id":"changepw-button","disabled":_vm.changePwText === 'Changing...'}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.changePwText === 'Changing...'),expression:"changePwText === 'Changing...'"}],attrs:{"small":"","type":"grow"}}),_c('fa-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.changePwText === 'Password Changed!'),expression:"changePwText === 'Password Changed!'"}],staticClass:"mr-2",attrs:{"id":"form-save-icon-check","icon":"check"}}),_vm._v(" "+_vm._s(_vm.changePwText)+" ")],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }