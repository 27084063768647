import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';

// List icons to use here
import {
  faUser, faLock, faPhone, faExternalLinkAlt, faCaretRight, faAt, faSignOutAlt, faSpinner,
  faDoorOpen, faCheck, faPlusSquare, faBan, faExclamationCircle, faQuestionCircle, faTrashAlt,
  faSearch, faExclamationTriangle, faClipboardCheck, faEdit, faWrench, faTools, faPrint,
  faCheckCircle, faBinoculars, faAngleDoubleRight, faAngleDoubleDown, faCheckSquare, faSignInAlt,
  faReply, faCaretLeft, faSlidersH, faRedo, faFileExport, faInfoCircle, faMobile, faFileContract,
  faDatabase, faColumns, faHistory, faClipboardList, faUserFriends, faUserSlash, faQrcode,
} from '@fortawesome/free-solid-svg-icons';
import {
  faIdCard, faFileAlt, faArrowAltCircleLeft, faArrowAltCircleRight, faSave, faCircle, faSquare,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Register icons to use in Vue templates
library.add(
  faUser,
  faLock,
  faPhone,
  faExternalLinkAlt,
  faCaretRight,
  faAt,
  faIdCard,
  faSignOutAlt,
  faFileAlt,
  faSave,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faSpinner,
  faDoorOpen,
  faCheck,
  faPlusSquare,
  faBan,
  faExclamationCircle,
  faQuestionCircle,
  faTrashAlt,
  faSearch,
  faExclamationTriangle,
  faClipboardCheck,
  faEdit,
  faWrench,
  faTools,
  faCircle,
  faCheckCircle,
  faPrint,
  faBinoculars,
  faAngleDoubleRight,
  faAngleDoubleDown,
  faCheckSquare,
  faSquare,
  faSignInAlt,
  faReply,
  faCaretLeft,
  faSlidersH,
  faRedo,
  faFileExport,
  faInfoCircle,
  faMobile,
  faFileContract,
  faDatabase,
  faColumns,
  faHistory,
  faClipboardList,
  faUserFriends,
  faUserSlash,
  faQrcode,
);
Vue.component('fa-icon', FontAwesomeIcon);
