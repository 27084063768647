<template>
  <validation-provider
    v-if="valRules !== undefined && valRules !== null && valRules !== ''"
    :vid="`valprov-${fieldId}`"
    :name="fieldName || ''"
    :rules="valRules"
    v-slot="valContext"
    :immediate="immediateVal"
    :mode="valMode"
    slim
    v-bind="valProviderAttrs"
  >
    <b-form-group
      v-if="renderGroup"
      :label="fieldLabel"
      :label-for="fieldId"
      :id="`grp-${fieldId}`"
      :name="`group-${htmlFieldName}`"
      :description="fieldDescription"
      v-bind="formGroupAttrs"
    >
      <slot :getValidationState="getValidationState(valContext, valFunction)"/>
      <b-form-invalid-feedback
        :id="`validation-feedback-${fieldId}`"
        :name="`validation-feedback-${htmlFieldName}`"
        :state="getValidationState(valContext, valFunction)"
      >
        {{ valContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- If we dont want the form group to render, just render slot content and invalid feedback display div -->
    <template v-else>
      <label v-if="fieldLabel" :id="`label-${fieldId}`" :for="fieldId" class="d-block mb-1">{{ fieldLabel }}</label>
      <slot :getValidationState="getValidationState(valContext, valFunction)"/>
      <b-form-invalid-feedback
        :id="`validation-feedback-${fieldId}`"
        :name="`validation-feedback-${htmlFieldName}`"
        :state="getValidationState(valContext, valFunction)"
      >
        {{ valContext.errors[0] }}
      </b-form-invalid-feedback>
    </template>
  </validation-provider>

  <!-- If validation rules are not passed in, render without val provider wrapper -->
  <b-form-group
    v-else-if="renderGroup"
    :label="fieldLabel"
    :label-for="fieldId"
    :id="`grp-${fieldId}`"
    :name="`group-${htmlFieldName}`"
    :description="fieldDescription"
    v-bind="formGroupAttrs"
  >
    <slot :getValidationState="null" />
  </b-form-group>

  <!-- If we dont have validation rules, and we dont want the form group to render, just render slot content alone -->
  <span v-else>
    <label v-if="fieldLabel" :id="`label-${fieldId}`" :for="fieldId" class="d-block mb-1">{{ fieldLabel }}</label>
    <slot :getValidationState="null" />
  </span>
</template>

<script>
import dayjs from 'dayjs';
import { ValidationProvider, extend } from 'vee-validate';
import { required, email, min, max, numeric } from 'vee-validate/dist/rules';
import { CoiError } from '../../../frontEndErrorHandler';

// Custom Validation rules used by eCOI
extend('matchfield', {
  validate(value, { target }) {
    return value === target;
  },
  params: ['target','messageStr'],
  message: (fieldName, placeholders) => {
    return (placeholders.messageStr ? placeholders.messageStr : 'The {_field_} field does not match');
  },
});
extend('hasupperlower', {
  validate(value) {
    const regEx = new RegExp('^(?=.*[a-z])(?=.*[A-Z])');
    return regEx.test(value);
  },
  message: 'The {_field_} field must include at least one upper and one lower case letter',
});
extend('hasnumber', {
  validate(value) {
    const regEx = new RegExp('^(?=.*[0-9])');
    return regEx.test(value);
  },
  message: 'The {_field_} field must include at least one number',
});
extend('hassymbol', {
  validate(value) {
    const regEx = new RegExp('^(?=.*[-_!@#\\$%\\^&\\*])');
    return regEx.test(value);
  },
  message: 'The {_field_} field must include at least one of the following symbols: - _ ! @ # $ % & *',
});
extend('fieldnotblank', {
  validate(value) {
    // regex: first character is not blank
    const regEx = new RegExp('^[a-zA-Z0-9][a-zA-Z0-9 ]+$');
    return (value.length !== 0 && regEx.test(value));
  },
  message: 'The {_field_} field cannot be blank',
});
extend('email', {
  ...email,
  message: '\'{_value_}\' is not a valid email address'
});
extend('min', {
  ...min,
  message: 'The {_field_} field must have at least {length} characters'
});
extend('max', {
  ...max,
  message: 'The {_field_} field cannot have more than {length} characters'
});
extend('numeric', {
  ...numeric,
  message: 'The {_field_} field can only contain numbers'
});
extend('decimal', {
  validate(value) {
    const regEx = new RegExp('^\\d*\\.?\\d*$');
    return regEx.test(value);
  },
  message: 'The {_field_} field must be a valid number (no commas, special characters, letters)',
});
extend('required', {
  ...required,
  message: 'The {_field_} field is required'
});
extend('associatedSubObjCreated', {
  validate(value) { return !!value; },
  message: 'Must create at least one {_field_}'
});
extend('mustSelectEntityRelationship', {
  ...required,
  message: 'Must select at least one entity relationship'
});
extend('mustReportCoi', {
  validate(value) { return !!value; },
  message: 'Must answer at least one question "yes" if reporting COI'
});
extend('endDateAfterStartDate', {
  validate(value, target) {
    return dayjs(value).diff(dayjs(target)) >= 0;
  },
  message: 'End date must be on or after the start date'
});
extend('noDefaultName', {
  validate(value) {
    return 'new entity' !== value.toLowerCase().trim();
  },
  message: 'Must update {_field_}'
});

export default {
  name: 'ValidationProviderPage',
  components: {
    ValidationProvider,
  },
  // Inject the form ID from the parent form, so it doesn't need to be passed in
  inject: {
    formIdParent: { default: null },
  },
  props: {
    formId: { type: String, default() { return this.formIdParent; } },
    fieldId: { type: String, required: true },
    fieldName: { type: String, required: true },
    fieldLabel: { type: String, default: '' },
    fieldDescription: { type: String, default: '' },
    renderGroup: { type: Boolean, default: true },
    valFunction: { type: String, default: undefined },
    valRules: { type: [String, Object], default: '' },
    valMode: { type: String, default: 'aggressive' },
    valOnLoad: { type: Boolean, default: false },
    // Can pass any extra bootstrap or vee validate attributes directly to their base components using objects below
    formGroupAttrs: { type: Object, default: null },
    valProviderAttrs: { type: Object, default: null },
  },
  computed: {
    immediateVal() { return this.valOnLoad || this.$store.getters.formObj(this.formId)?.showValidations; },
    htmlFieldName() { return this.fieldName?.replace(/ /g, '-')?.toLowerCase() || ''; },
  },
  methods: {
    getValidationState({ validated, untouched, changed, passed, valid }, valFunction) {
      try {
        if (valFunction === 'disabled') return null;
        if (valFunction === 'errorsOnly') {
          if (!validated && untouched) return null;
          return (passed === true ? null : false);
        }
        if (valFunction === 'showOnValidate') {
          if (this.immediateVal) return valid;
          return null;
        }
        if (valFunction === 'notRequired') {
          if (untouched || (!changed && passed)) return null;
          return passed;
        }

        // Default validation function if not specified
        if (!validated && untouched) return null;
        return passed;
      } catch (e) {
        throw new CoiError(e, '.ValidationProvider.vue (getValidationState)');
      }
    },
  },
};
</script>
