// Vuex module for storing and accessing environment specific details
import Vue from 'vue';

export default {
  state: {
    name: '',
    displayName: '',
    browser: '',
    startLoadingCount: 0,
    stopLoadingCount: 0,
    loadingText: null,
  },
  getters: {
    isLoading: (state) => (state.startLoadingCount > state.stopLoadingCount),
    loadingText: (state) => (state.loadingText || ''),
  },
  mutations: {
    setEnvironment(state, browserName) {
      state.browser = browserName;
      switch (window.location.hostname) {
        case 'localhost':
          state.name = 'development';
          state.displayName = 'Development';
          break;
        case 'coiqa.ohsu.edu':
          state.name = 'qa';
          state.displayName = 'QA';
          break;
        case 'coiusr.ohsu.edu':
          state.name = 'user';
          state.displayName = 'User';
          break;
        default:
          state.name = 'production';
          state.displayName = '';
      }
    },
    // Split loading counts into separate variables (startLoadingCount and stopLoadingCount) so that mutations for start/stop loading
    // don't run into data clashes
    startLoading(state, loadingTextUpdate) {
      state.startLoadingCount += 1;

      // Only update loading text if this is the first time loading is starting, or if new loading text was passed in
      if (loadingTextUpdate || !state.loadingText) {
        state.loadingText = loadingTextUpdate || 'Loading';
      }
      Vue.$log.debug(`Started Loading, start load count: ${state.startLoadingCount}, stop load count: ${state.stopLoadingCount}, loading text: ${state.loadingText}`);
    },
    stopLoading(state) {
      state.stopLoadingCount += 1;

      // Reset loading text if stop loading was called as many times as start loading
      if (state.startLoadingCount <= state.stopLoadingCount) {
        state.loadingText = null;
      }
      Vue.$log.debug(`Stopped Loading, start load count: ${state.startLoadingCount}, stop load count: ${state.stopLoadingCount}, loading text: ${state.loadingText}`);
    },
    resetLoading(state) {
      state.startLoadingCount = 0;
      state.stopLoadingCount = 0;
      state.loadingText = null;
      Vue.$log.debug('Reset loading counts and overlay');
    },
  },
};
