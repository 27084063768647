// Vuex module for managing form pages, and rendering different form components when any form
// is used
import Vue from 'vue';
import { cloneDeep, defaultsDeep, isEmpty, isEqual, filter, orderBy } from 'lodash';
import { getField, updateField } from 'vuex-map-fields';
import { CoiError } from '../frontEndErrorHandler';

const getDefaultState = () => {
  const defaultState = {
    forms: {},
    pages: {},
  };
  return cloneDeep(defaultState);
};

/**
 * Form object structure:
 * {
 *  id: 'internalFormId',    // Unique, internal ID of form to reference it
 *  formType: 'disclosure',  // Type of form this is
 *  currentPageId: '',       // String representing the ID of the currently displayed form page
 *  isReadOnly: false        // Bool to determine if form should render in read-only mode.
 *  isFormNavDisabled: false // Bool to enable or disable all navigation on the form (can be done as needed for load/save operations)
 *  showValidations: false   // Bool to determine if validation feedback should be shown
 *  showPrintVersion: false  // Bool to determine if form should display in print mode (all pages shown at once, read only)
 *  localData: {},           // Form data being locally modified by the user on the current form
 *  serverData: {},          // Reference to form data currently saved on server, to reference during save operations
 * }
 */
const defaultForm = {
  id: '',
  formType: '',
  currentPageId: '',
  isReadOnly: false,
  isFormNavDisabled: false,
  showValidations: false,
  showPrintVersion: false,
  localData: {},
  serverData: {},
};

/**
 * Page object structure:
 * {
 *  id: 'internalPageId',            // Unique, internal ID of page to reference it
 *  formId: 'internalFormId',        // ID of the form object (defined above) that this page belongs to
 *  displayName: 'Page Name',        // Display name for the page, to render in sidebar entry
 *  formMenuLevel: 1,                // Optional- Indentation level of page when rendered in sidebar menu
 *  displayOrder: 1,                 // Optional- Order the page should be rendered in in the list of sidebar menu links
 *  badgeContent: 'String to Render' // Optional- Content that should show in the badge next to the menu item for this page
 *  isHidden: false                  // Boolean to set if page should be hidden from the list of menu links
 *  isReadOnly: false                // Boolean to set if an individual page should be read-only
 *  defaultPage: false               // Boolean to set if page should be shown as default when no pageId is present in the URL querystring
 *  isValid: true                    // Boolean to set if page is valid (no validation errors)
 *  formControls: {                  // Determines how the form control buttons should display on the page
 *    backBtn: { display: 'enabled', position: 'left' },
 *    nextBtn: { display: 'enabled', position: 'right' },
 *    printBtn: { display: 'enabled', position: 'center' },
 *  }
 * }
 */
const defaultPage = {
  id: '',
  formId: '',
  displayName: 'Unnamed Page',
  formMenuLevel: 1,
  displayOrder: 1,
  badgeContent: null,
  isHidden: false,
  isReadOnly: false,
  defaultPage: false,
  isValid: true,
  formControls: {},
};

export default {
  state: getDefaultState(),
  getters: {
    getField, // vuex-map-fields getter
    formObj: (state) => (formId) => (state.forms?.[formId] || {}),
    pageObj: (state) => (pageId) => (state.pages?.[pageId] || {}),
    localData: (state) => (formId) => (state.forms?.[formId]?.localData || {}),
    serverData: (state) => (formId) => (state.forms?.[formId]?.serverData || {}),
    pagesForForm: (state) => (formId) => (orderBy(filter(state.pages, ['formId', formId]), ['displayOrder'], ['asc'])),
    visiblePagesForForm: (state, getters) => (formId) => (filter(getters.pagesForForm(formId), ['isHidden', false])),
    firstVisiblePage: (state, getters) => (formId) => {
      const visiblePages = getters.visiblePagesForForm(formId);
      if (visiblePages.length > 0) return visiblePages[0];
      return null;
    },
    nextVisiblePage: (state, getters) => (formId, currPageId) => {
      const nextPages = filter(
        getters.visiblePagesForForm(formId),
        (page) => (page?.displayOrder > state.pages?.[currPageId]?.displayOrder),
      );
      if (nextPages.length > 0) return nextPages[0];
      return null;
    },
    prevVisiblePage: (state, getters) => (formId, currPageId) => {
      const prevPages = (orderBy(filter(
        getters.visiblePagesForForm(formId),
        (page) => (page?.displayOrder < state.pages?.[currPageId]?.displayOrder),
      ), ['displayOrder'], ['desc']));
      if (prevPages.length > 0) return prevPages[0];
      return null;
    },
    formHasChanges: (state) => (formId) => !isEqual(
      state.forms?.[formId]?.localData,
      state.forms?.[formId]?.serverData,
    ),
    // Form is set to read-only state if the isReadOnly flag is set to true, or if the user is looking at the
    // printer version of the form
    formIsReadOnly: (state) => (formId) => (state.forms?.[formId]?.isReadOnly === true
      || state.forms?.[formId]?.showPrintVersion === true),
    // Page is set to read-only state if the isReadOnly flag is set to true, or if the parent form is read-only,
    // or if the user is looking at the printer version of the form
    pageIsReadOnly: (state, getters) => (pageId) => (state.pages?.[pageId]?.isReadOnly === true
      || getters.formIsReadOnly(getters.pageObj(pageId)?.formId) === true),
    formDataLoaded: (state) => (formId) => (!isEmpty(state.forms?.[formId]?.localData)),
  },
  mutations: {
    updateField, // vuex-map-fields mutator
    setFormCurrentPageId(state, { formId, currentPageId }) { state.forms[formId].currentPageId = currentPageId; },
    setFormReadOnlyBool(state, { formId, isReadOnly }) { state.forms[formId].isReadOnly = (isReadOnly === true); },
    setFormDisableNavBool(state, { formId, isFormNavDisabled }) {
      state.forms[formId].isFormNavDisabled = (isFormNavDisabled === true);
    },
    setFormShowValidationsBool(state, { formId, showValidations }) {
      state.forms[formId].showValidations = (showValidations === true);
    },
    setFormPrintVersionBool(state, { formId, showPrintVersion }) {
      state.forms[formId].showPrintVersion = (showPrintVersion === true);
    },
    setFormData(state, { formId, formDataObj }) {
      state.forms[formId].localData = cloneDeep(formDataObj);
      state.forms[formId].serverData = cloneDeep(formDataObj);
    },
    setPageName(state, { pageId, displayName }) { state.pages[pageId].displayName = (displayName || 'Unnamed'); },
    setPageIsReadOnlyBool(state, { pageId, isReadOnly }) { state.pages[pageId].isReadOnly = (isReadOnly === true); },
    setPageIsValidBool(state, { pageId, isValid }) { state.pages[pageId].isValid = (isValid === true); },
    setPageIsHiddenBool(state, { pageId, isHidden }) { state.pages[pageId].isHidden = (isHidden === true); },
    setPageBadgeContent(state, { pageId, badgeContent }) { state.pages[pageId].badgeContent = badgeContent; },
    // Use _vm.$set and _vm.$delete to preserve Vue reactivity throughout app
    setPageFormControls(state, { pageId, formControls }) {
      this._vm.$set(state.pages[pageId], 'formControls', formControls);
    },
    createForm(state, formObj) { this._vm.$set(state.forms, formObj.id, formObj); },
    deleteForm(state, formId) { this._vm.$delete(state.forms, formId); },
    createFormPage(state, pageObj) { this._vm.$set(state.pages, pageObj.id, pageObj); },
    deleteFormPage(state, pageId) { this._vm.$delete(state.pages, pageId); },
    resetFormModuleState(state) {
      // Use object.assign to avoid losing state observer/reactivity
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    /**
     * Simple action to reset entire form vuex module state to default values
     * @param {Object} store - Standard Vuex object reference
     */
    async resetFormModuleState({ commit }) {
      try {
        Vue.$log.debug('Resetting entire form module state');
        commit('resetFormModuleState');
      } catch (e) {
        throw new CoiError(e, 'form.js (resetFormModuleState)');
      }
    },

    /**
     * Action to set the formDataLocal and formDataServer to have needed data for for editing. Takes a single
     * Javascript object with all form field data, and clones into two new objects to make sure original data isn't updated
     * @param {Object} store - Standard Vuex object reference
     * @param {String} formId - ID of the form to update data for.
     * @param {Object} formDataObj - Javascript object with all properties needed for form fields.
     */
    async setFormData({ state, commit }, { formId, formDataObj }) {
      try {
        Vue.$log.debug(`Setting form data local/server states:\nformId:\t${formId}\nformDataObj:\n${formDataObj}`);
        if (!formId) throw new CoiError('formId is required to set form data state', 'form.js (setFormData)');
        if (!formDataObj || isEmpty(formDataObj)) throw new CoiError('formDataObj is required to set form data state', 'form.js (setFormData)');
        if (isEmpty(state.forms[formId])) throw new CoiError(`Form with ID '${formId}' doesn't exist, so cannot set data`, 'form.js (setFormData)');
        commit('setFormData', { formId, formDataObj });
      } catch (e) {
        throw new CoiError(e, 'form.js (setFormData)');
      }
    },

    /**
     * Function to set various form-level settings. Note that this does not include actual form data that user's edit on the form,
     * that is done through vuex-map-fileds instead.
     * @param {Object} store - Standard Vuex object reference
     * @param {Object} formSettings - Object containig various form settings that can be updated.
     * @param {String} formSettings.formId - Required. ID of the form to update, needed to get a reference to the form object itself
     * @param {String} formSettings.currentPageId - String of currently displayed page ID
     * @param {Boolean} formSettings.isReadOnly - Boolean value to determine if form should be rendered in read-only mode
     * @param {Boolean} formSettings.isFormNavDisabled - Boolean value to determine if form navigation should be disabled
     * @param {Boolean} formSettings.showValidations - Boolean value to determine if form validations are shown or hidden
     * @param {Boolean} formSettings.showPrintVersion - Boolean value to determine if form should render print view (all pages shown read only)
     */
    async setFormSettings(
      { state, commit },
      { formId, currentPageId, isReadOnly, isFormNavDisabled, showValidations, showPrintVersion },
    ) {
      try {
        Vue.$log.debug('Setting form options. Settings object passed in: ', JSON.stringify({ formId, currentPageId, isReadOnly, isFormNavDisabled, showValidations, showPrintVersion }));
        if (!formId) throw new CoiError('formId is required to update form settings', 'form.js (setFormSettings)');
        if (isEmpty(state.forms[formId])) throw new CoiError(`Form with ID '${formId}' doesn't exist, so cannot set settings`, 'form.js (setFormSettings)');
        if (currentPageId !== undefined) commit('setFormCurrentPageId', { formId, currentPageId });
        if (isReadOnly !== undefined) commit('setFormReadOnlyBool', { formId, isReadOnly });
        if (isFormNavDisabled !== undefined) commit('setFormDisableNavBool', { formId, isFormNavDisabled });
        if (showValidations !== undefined) commit('setFormShowValidationsBool', { formId, showValidations });
        if (showPrintVersion !== undefined) commit('setFormPrintVersionBool', { formId, showPrintVersion });
      } catch (e) {
        throw new CoiError(e, 'form.js (setFormSettings)', { formId, currentPageId, isReadOnly, showValidations, showPrintVersion });
      }
    },

    /**
     * Function to set various page-level settings on the page objects that the form generates and manages in Vuex
     * @param {Object} store - Standard Vuex object reference
     * @param {Object} pageSettings - Object containig various page settings that can be updated.
     * @param {String} pageSettings.pageId - Required. ID of the page object to update, needed to get a reference to the page object itself
     * @param {String} pageSettings.displayName - Display name of form page
     * @param {Boolean} pageSettings.isReadOnly - Boolean value to determine if page should be rendered in read-only mode
     * @param {Boolean} pageSettings.isValid - Boolean value to toggle if page is valid or not
     * @param {Boolean} pageSettings.isHidden - Boolean value to toggle if page is hidden from view on form
     * @param {String} pageSettings.badgeContent - String to render in badge icon on form page in sidebar
     * @param {Object} pageSettings.formControls - Object to update form controls for that page
     */
    async setPageSettings(
      { state, commit },
      { pageId, displayName, isReadOnly, isValid, isHidden, badgeContent, formControls },
    ) {
      try {
        Vue.$log.debug('Setting page options. Settings object passed in: ', JSON.stringify({ pageId, displayName, isReadOnly, isValid, isHidden, badgeContent, formControls }));
        if (!pageId) throw new CoiError('Form page ID must be specified to set form settings', 'form.js (setPageSettings)');
        if (isEmpty(state.pages[pageId])) throw new CoiError(`Page with ID '${pageId}' doesn't exist, so cannot set settings`, 'form.js (setPageSettings)');
        // Need to do explicit undefined checks because some of these set boolean values
        if (displayName !== undefined) commit('setPageName', { pageId, displayName });
        if (isReadOnly !== undefined) commit('setPageIsReadOnlyBool', { pageId, isReadOnly });
        if (isValid !== undefined) commit('setPageIsValidBool', { pageId, isValid });
        if (isHidden !== undefined) commit('setPageIsHiddenBool', { pageId, isHidden });
        if (badgeContent !== undefined) commit('setPageBadgeContent', { pageId, badgeContent });
        if (formControls !== undefined) commit('setPageFormControls', { pageId, formControls });
      } catch (e) {
        throw new CoiError(e, 'form.js (setPageSettings)', { pageId, displayName, isValid, isHidden, badgeContent, formControls });
      }
    },

    /**
     * Function for creating a form reference object to access in Vuex. Called automatically from Form.vue during the mounted lifecycle hook
     * @param {Object} store - Standard Vuex object reference
     * @param {Object} formData - Related data for the form to create. Fields are defaulted if not present.
     */
    async createForm({ state, commit }, formData) {
      try {
        if (!formData.id) throw new CoiError('Form page ID must be specified to create a new form page', 'form.js (createForm)');
        if (!isEmpty(state.forms[formData.id])) throw new CoiError(`Form with ID '${formData.id}' already exists`, 'form.js (createForm)');

        // Fill in default values for form data
        const newForm = defaultsDeep({}, formData, defaultForm);
        Vue.$log.debug('Creating form. Form object after defaults: ', JSON.stringify(newForm));
        commit('createForm', newForm);
        return newForm;
      } catch (e) {
        throw new CoiError(e, 'form.js (createForm)');
      }
    },

    /**
     * Function for removing a form from Vuex. Called automatically from Form.vue during the destroy lifecycle hook
     * Note: Should not be used elsewhere in the application
     * @param {Object} store - Standard Vuex object reference
     * @param {String} formId - Required. ID of the form object to delete, needed to get a reference to the form object itself
     */
    async deleteForm({ state, commit }, formId) {
      try {
        Vue.$log.debug('Deleting form with ID ', formId);
        if (!formId) throw new CoiError('Form ID must be specified to delete a form', 'form.js (deleteForm)');
        if (isEmpty(state.forms[formId])) throw new CoiError(`Form with ID '${formId}' doesn't exist, so cannot be deleted`, 'form.js (deleteForm)');
        commit('deleteForm', formId);
      } catch (e) {
        throw new CoiError(e, 'form.js (deleteForm)');
      }
    },

    /**
     * Function for creating a form page reference object to access in Vuex. Called automatically from FormPage.vue during the mounted lifecycle hook
     * @param {Object} store - Standard Vuex object reference
     * @param {Object} pageData - Related data for the form page to create. Fields are defaulted if not present.
     */
    async createFormPage({ state, commit }, pageData) {
      try {
        if (!pageData.id) throw new CoiError('Form page ID must be specified to create a new form page', 'form.js (createFormPage)');
        if (!pageData.formId) throw new CoiError('New page object must have a parent form ID', 'form.js (createFormPage)');
        if (isEmpty(state.forms[pageData.formId])) throw new CoiError(`Form with ID '${pageData.formId}' doesn't exist, so cannot create page for it`, 'form.js (createFormPage)');
        if (!isEmpty(state.pages[pageData.id])) throw new CoiError(`Page with ID '${pageData.id}' already exists`, 'form.js (createFormPage)');

        // Fill in default values for page data
        const newPage = defaultsDeep({}, pageData, defaultPage);
        Vue.$log.debug('Creating form page. Page object after defaults: ', JSON.stringify(newPage));
        commit('createFormPage', newPage);
        return newPage;
      } catch (e) {
        throw new CoiError(e, 'form.js (createFormPage)');
      }
    },

    /**
     * Function for removing a form page from Vuex. Called automatically from FormPage.vue during the destroy lifecycle hook
     * Note: Should not be used elsewhere in the application
     * @param {Object} store - Standard Vuex object reference
     * @param {String} pageId - Required. ID of the page object to delete, needed to get a reference to the page object itself
     */
    async deleteFormPage({ state, commit }, pageId) {
      try {
        Vue.$log.debug('Deleting form page with ID ', pageId);
        if (!pageId) throw new CoiError('Form page ID must be specified to delete a form page', 'form.js (deleteFormPage)');
        if (isEmpty(state.pages[pageId])) throw new CoiError(`Page with ID '${pageId}' doesn't exist, so cannot be deleted`, 'form.js (deleteFormPage)');
        commit('deleteFormPage', pageId);
      } catch (e) {
        throw new CoiError(e, 'form.js (deleteFormPage)');
      }
    },
  },
};
