// Main Vuex store definition file
import Vue from 'vue';
import Vuex from 'vuex';
import formMod from './form';
import sessionMod from './session';
import environmentMod from './environment';

// Enable vuex strict mode in dev only to make sure we are following proper Vuex guidelines, but
// Don't use the extra syncronouse processing in real environments
const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    form: formMod,
    session: sessionMod,
    env: environmentMod,
  },
  strict: debug,
});
