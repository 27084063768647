<template>
  <b-container fluid id="login-page">
    <div class="mt-3" v-html="announcements"></div>

    <!-- Enter Username Popup -->
    <b-modal
      id="modal-enter-username"
      title="Login"
      hide-backdrop
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
      :static="testMode"
    >
      <validation-observer ref="username-observer" v-slot="{ passes }">
        <b-form @submit.stop.prevent="passes(checkUser)" id="check-user-form">
          <InputField
            v-model="userName"
            ref="loginUsername"
            fieldId="login-username"
            fieldName="Username"
            fieldPlaceholder="Email"
            valFunction="errorsOnly"
            valRules="required"
            class="mb-3"
            :isReadOnly="modalLoading"
            :inputAttrs="{ autofocus: true }"
          >
            <template v-slot:prepend>
              <b-input-group-text><fa-icon icon="user"/></b-input-group-text>
            </template>
          </InputField>
          <div class="d-flex flex-row justify-content-between">
            <b-button
              id="test-accts-btn"
              variant="outline-primary"
              v-if="$store.state.env.name != 'production' && $store.state.env.name != 'user'"
              :disabled="modalLoading"
              v-b-modal.modal-test-accts
            >
              Test Accounts
            </b-button>
            <b-button
              id="check-user-btn"
              variant="primary"
              class="px-5"
              type="submit"
              :disabled="modalLoading"
            >
              <b-spinner
                v-if="modalLoading"
                label="Checking user account type"
                class="mr-2"
                small
              />
              {{ modalLoading ? 'Checking...' : 'Next'}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
      <template v-slot:modal-footer>
        <b-link
          id="create-account-link"
          class="card-link mr-auto"
          v-b-modal.modal-create-account
          href="#"
        >
          Create Account
        </b-link>
      </template>
    </b-modal>

    <!-- Password Entry Modal -->
    <b-modal
      id="modal-enter-password"
      title="Password Entry"
      @hidden="backToStart"
      hide-backdrop
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
      :static="testMode"
      @shown="passwordFieldFocus"
    >
    <validation-observer ref="password-observer" v-slot="{ passes }">
      <b-form @submit.stop.prevent="passes(createPassword)" id="password-form">
      <template v-if="loginContext === 'newPWLogin'">
        <p>
        Please create a new password for logging into the eCoI system:
        </p>
        <p class="font-italic"><small>
          Password is specific to eCoI system and will not affect access to other OHSU systems.
        </small></p>
        <!-- New Password Field -->
        <InputField
          v-model="newPw"
          fieldId="create-new-pw"
          fieldName="New Password"
          fieldLabel="New Password"
          inputType="password"
          valMode="eager"
          :valRules="{
            required: true, min: 8, max: 36, hasupperlower: true, hasnumber: true, hassymbol: true
          }"
          :inputAttrs="{ maxlength: '36' }"
        />

        <!-- New Password Confirm Field -->
        <InputField
          v-model="newPwc"
          fieldId="create-new-pwc"
          fieldName="Confirm Password"
          fieldLabel="Confirm New Password"
          inputType="password"
          valMode="eager"
          :valRules="{
            required: true,
            matchfield: {
              target: newPw,
              messageStr: 'Must match Password field above',
            },
          }"
          :inputAttrs="{ maxlength: '36' }"
        />
        <b-button
              id="newpw-btn"
              variant="primary"
              class="px-5"
              type="submit"
              :disabled="modalLoading"
            >
              <b-spinner
                v-if="modalLoading"
                label="Logging In"
                class="mr-2"
                small
              />
              Create
            </b-button>
      </template>
      </b-form>
    </validation-observer>

      <template v-if="loginContext === 'tfaLogin'">
        <p>
        A six-digit verification code was emailed to your email address.
        Enter that code along with your new password below:
        </p>
      </template>

      <p v-else-if="loginContext === 'testUserLogin'">
        You are logging in as test user {{userName}}
      </p>

      <p v-else-if="loginContext === 'unregistered'">
        The username<b> {{userName}} </b>was not found.
        <ul class="mt-2">
          <li>If you have previously submitted an OHSU eCoI disclosure or have ever requested an
              external employee ID (EID) from HR, please email
              <b-link href="mailto:coir@ohsu.edu">coir@ohsu.edu</b-link> for assistance.
          </li>
          <li>If you have never submitted an OHSU eCoI disclosure or requested an EID, and:
            <ul class="mt-2">
              <li class="list-circle">
                <b>need to be research compliant</b> in an OHSU research system (eIRB, eIBC, eIACUC),
                submit an EID request form
                <b-link href="https://www.ohsu.edu/human-resources/external-employee-id-request-form/" target="_blank">
                here<fa-icon icon="external-link-alt" class="ml-1" /></b-link>.
              </li>
              <li class="list-circle">
                <b>do not need to be research compliant </b>
                in any OHSU systems listed above and only need to submit an eCoI disclosure, request a new account
                <b-link to="/register">here</b-link>.
              </li>
            </ul>
          </li>
        </ul>
      </p>

      <p v-else-if="loginContext  === 'ldapLogin'">
        {{userName}}, please log in with your network password:
      </p>

      <validation-observer v-if="loginContext !== 'unregistered' && loginContext !== 'newPWLogin'"
        ref="password-observer" v-slot="{ passes }">
        <b-form @submit.stop.prevent="passes(userLogin)" id="password-form">
          <InputField
            v-model="password"
            fieldId="login-password"
            fieldName="Password"
            fieldPlaceholder="Password"
            valFunction="errorsOnly"
            valRules="required"
            inputType="password"
          >
            <template v-slot:prepend>
              <b-input-group-text><fa-icon icon="lock"/></b-input-group-text>
            </template>
          </InputField>
          <InputField
            v-if="loginContext === 'tfaLogin'"
            v-model="totpcode"
            fieldId="login-totpcode"
            fieldName="TOTPCode"
            fieldPlaceholder="Six-Digit Verification Code"
            valFunction="errorsOnly"
            valRules="required"
            class="mb-3"
            inputType="number"
            hide-spin-buttons="1"
          >
            <template v-slot:prepend>
              <b-input-group-text><fa-icon icon="mobile"/></b-input-group-text>
            </template>
          </InputField>
          <div class="d-flex flex-row justify-content-between">
            <b-button
              id="back-btn"
              variant="outline-primary"
              :disabled="modalLoading"
              @click="backToStart"
            >
              Back
            </b-button>
            <b-button
              id="login-btn"
              variant="primary"
              class="px-5"
              type="submit"
              @click="resendClicked=false;sendCodeText='Re-Send Code'"
            >
              <b-spinner
                v-if="modalLoading"
                label="Logging In"
                class="mr-2"
                small
              />
              {{ modalLoading ? 'Logging In...' : 'Log In'}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>

      <b-alert
        v-model="showLoginError"
        variant="danger"
        id="login-errors-alert"
        class="mt-3 mb-0 text-center"
        aria-label="login-errors"
        dismissible
      >
        {{ loginError }} <br>
        <b-link v-if="loginError === 'Incorrect Password'" to="/forgotPassword"> [Forgot Password] </b-link>
        <small v-if="errResendLogin">
          <br>
          <b-button
              id="resend-code-btn"
              variant="primary"
              :disabled="resendClicked"
              @click="checkUser(); sendCodeText='Code Sent!'; resendClicked=true"
            >
            {{ sendCodeText }}
            </b-button>
        </small>
      </b-alert>

      <template v-slot:modal-footer>
        <p v-if="loginContext === 'ldapLogin'">
          For assistance with network passwords,
          please call OHSU ITG at 503-494-2222 or 800-328-2422.
        </p>
        <div v-else>
          <b-link to="/forgotPassword" class="force-left"> Forgot Password </b-link>
          <b-link
            id="reload-login-link"
            class="card-link"
            @click="backToStart();"
          >
            Return to Login
          </b-link>
        </div>
      </template>
    </b-modal>

    <!-- Create Account Modal Popup -->
    <b-modal
      id="modal-create-account"
      title="Create eCoI Account"
      ok-only
      ok-title="Go Back"
      ok-variant="primary"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
      :static="testMode"
    >
      <h6><b>OHSU Employees and Faculty:</b></h6>
      <p>
        Do not request a new account.
        Please log in using your OHSU network information.
      </p>
      <h6><b>OHSU Students and Non-OHSU collaborators with an external employee ID (EID)
         assigned by HR:</b></h6>
      <p>
        Do not request a new account.
        Please login with your email and eCoI password. If you email address is not found in the
        eCoI system, plese email coir@ohsu.edu for assistance.
      </p>
      <h6>
        <b>Non-OHSU Collaborators without an external employee ID (EID) assigned by HR
          who do not need to be research compliant in the OHSU research systems
          (eIRB, eIBC, eIACUC):
        </b>
      </h6>
      <p>
        Proceed to the <b-link to="/register">Create Account</b-link>
        page to request an eCoI account.
      </p>
    </b-modal>

    <!-- Update Password Modal Popup -->
    <b-modal
      id="modal-update-pw"
      title="Set eCoI Password"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      ok-only
      ok-title="Close"
      ok-variant="primary"
      @ok="backToStart"
      centered
      :static="testMode"
    >
      <p>
        Please set a new password for your eCoI account.
      </p>
      <UserProfilePasswordComp :userData="userData" :currentPwProp="password"
        @pwChangeComplete="$bvModal.hide('modal-update-pw'); postLogin();" />
    </b-modal>

    <!-- Test Accounts Modal Popup (only in non-prod envs) -->
    <b-modal
      id="modal-test-accts"
      title="eCoI Test Accounts"
      ok-only
      ok-title="Close"
      ok-variant="secondary"
      size="xl"
      :static="testMode"
      v-if="$store.state.env.name != 'production'"
    >
      <h6>OHSU Users</h6>
      <p>SAML/LDAP is currently enabled for testing. Log in with your OHSU userName and password to test as needed.</p>
      <h6>Local Accounts</h6>
      <p>To test locally registered users (external people), you can use anyone from the list below:</p>
      <b-table
        id="table-test-users"
        :fields="[
        { key: 'id', label: 'ID' },
        { key: 'userName' },
        { key: 'fullName' },
        { key: 'roles' },
        { key: 'notes', formatter: (n) => (n && n.slice(0, 24)) },
        { key: 'loginAsUser', label: '', thStyle: { width: '10rem' } },
      ]"
        :items="testUsers"
        sort-by="notes"
        :sort-desc="false"
        outlined
        hover
        show-empty
      >
        <!-- Custom format for user roles display -->
        <template v-slot:cell(roles)="data">
          <small>
          {{
            (data.item.isCoiAdmin ? 'COI Admin, ' : '') +
            (data.item.isCoiAnalyst ? 'COI Analyst, ' : '') + (data.item.isCoiSuperReader ? 'COI Super Reader, ' : '') +
            (data.item.isCoiReader ? 'COI Reader, ' : '') + (data.item.isClinician ? 'Clinician, ' : '') +
            (data.item.isFaculty ? 'Faculty, ' : '') + (data.item.isSupervisor ? 'Supervisor, ' : '') +
            (data.item.isFiscalAuth ? 'Fiscal Authority, ' : '')
          }}
          </small>
        </template>

        <!-- Custom format for login button -->
        <template v-slot:cell(loginAsUser)="data">
          <b-button variant="primary" :id="`login-button-${data.item.id}`" size="sm"
            @click="userName = data.item.userName; password='Test1234!'; $bvModal.hide('modal-test-accts');">
            Login as user
          </b-button>
        </template>
      </b-table>
    </b-modal>
  </b-container>
</template>

<script>
import { endsWith, replace } from 'lodash';
import dayjs from 'dayjs';
import { ValidationObserver } from 'vee-validate';
import InputField from '@/views/components/form-controls/InputField.vue';
import UserProfilePasswordComp from '@/views/components/user-profile-forms/UserProfile-Password.vue';
import { genericGet, genericPost } from '../../controllers/common.controller';
import { CoiError } from '../../frontEndErrorHandler';

export default {
  name: 'Login',
  props: {
    testMode: { type: Boolean, default: false }, // Test mode allows us to render modal static for JEST unit tests
  },
  components: {
    ValidationObserver,
    InputField,
    UserProfilePasswordComp,
  },
  data() {
    return {
      loginError: '',
      sendCodeText: 'Re-Send Code',
      resendClicked: false,
      modalLoading: false,
      qrError: '',
      qrText: '',
      userName: '',
      password: '',
      totpcode: '',
      userData: {},
      testUsers: [],
      loginContext: '',
      announcements: '',
      newPw: '',
      newPwc: '',
    };
  },
  computed: {
    showLoginError() { return this.loginError !== ''; },
    errResendLogin() {
      // array of values that should trigger displaying a login error
      const errArr = ['Incorrect verification code. Please check your email and enter the most recent code received.',
        'Passcode expired, please click "Re-Send Code" to receive a new verification code.'];
      if (errArr.includes(this.loginError)) return true; return false;
    },
  },
  methods: {
    async backToStart() {
      this.password = '';
      this.totpcode = '';
      this.loginError = '';
      this.resendClicked = false;
      this.sendCodeText = 'Re-Send Code';
      this.$bvModal.hide('modal-test-accts');
      this.$bvModal.hide('modal-update-pw');
      this.$bvModal.hide('modal-enter-password');
      this.$bvModal.hide('modal-create-account');
      this.$bvModal.show('modal-enter-username');
    },

    // checks provided username for status (OHSU/External/Not Registered)
    async checkUser() {
      try {
        this.modalLoading = true;
        // Usernames are emails; if absent, add '@ohsu.edu' string
        let loginName = this.userName;
        const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
        if (!regexExp.test(loginName)) {
          loginName = replace(this.userName.toLowerCase().concat('@ohsu.edu'));
        }
        const { accessType } = await genericGet('user/checkRegStatus', { userName: loginName });
        this.loginContext = accessType || 'unregistered';
        this.$bvModal.hide('modal-enter-username');
        this.$bvModal.show('modal-enter-password');
        this.modalLoading = false;
      } catch (e) {
        throw new CoiError(e, 'Login.vue (checkUser)');
      }
    },

    async userLogin() {
      try {
        this.loginError = '';
        this.modalLoading = true;
        let loginName = this.userName;
        // If LDAP user typed out a full OHSU email, strip out the '@ohsu.edu' string, which will not work for LDAP
        if (this.loginContext === 'ldapLogin' && endsWith(this.userName.toLowerCase(), '@ohsu.edu')) {
          loginName = replace(this.userName.toLowerCase(), '@ohsu.edu', '');
        }
        this.userData = await this.$store.dispatch(
          this.loginContext,
          { username: loginName, password: this.password, code: this.totpcode },
        );
        // If a message was sent back rather than the user, something went wrong with login, and should be displayed as error
        if (this.userData.message) {
          this.$log.warn(`Login issues: ${this.userData.message}`);
          this.loginError = this.userData.message;
          this.modalLoading = false;
        } else if (this.loginContext === 'tfaLogin'
          && (!this.userData.nextPwChangeDate || dayjs(this.userData.nextPwChangeDate).isBefore(dayjs()))) {
          // If user password is expired, show the password change modal before finishing login
          this.$bvModal.show('modal-update-pw');
          this.modalLoading = false;
        } else {
          // Default to triggering post-login handler if no errors or issues came up during login
          this.postLogin();
        }
      } catch (e) {
        throw new CoiError(e, 'Login.vue (userLogin)');
      }
    },

    async createPassword() {
      try {
        // this.createPwText = 'Changing...';
        this.changePwResp = await genericPost('user/pwCreate', {
          userName: this.userName, newPw: this.newPw,
        });
        await this.$store.dispatch('refreshSession');
        await this.checkUser();
        this.newPw = '';
        this.newPwc = '';
      } catch (e) {
        throw new CoiError(e, 'UserProfile-Password.vue (createPassword)');
      }
    },

    /**
     * Method triggered after login is complete, handles redirects as needed
     */
    postLogin() {
      try {
        if (this.$route.query.redirectFrom) {
          // If user attempted to navigate to a specific page, and was redirected to login,
          // Navigate to that specific page after authentication.
          this.$router.push({ path: this.$route.query.redirectFrom });
        } else {
          // Default to navigating to inbox page
          this.$router.push({ name: 'SearchInbox' });
        }
      } catch (e) {
        throw new CoiError(e, 'Login.vue (postLogin)');
      }
    },

    async passwordFieldFocus() {
      this.$nextTick(() => {
        const passField = document.getElementById('field-login-password');
        if (passField) passField.focus();
      });
    },
  },

  /**
   * Load test users for display (if not in production)
   */
  async created() {
    try {
      // Do nothing if this is production
      if (this.$store.state.env.name !== 'production') {
        this.testUsers = (await genericGet('user/testUsers')) || [];
      }

      this.announcements = (await genericGet('loginBanner')) || '';
    } catch (e) {
      throw new CoiError(e, 'Login.vue (created)');
    }
  },
  async mounted() {
    this.$bvModal.show('modal-enter-username');
  },
};
</script>

<style lang="scss">
#login-page {
  background-color: $gray-200;
}
</style>
