/**
 * Set of helper functions which perform various types of web services calls common accross the COI application
 * Ideally, any calls which involve axios should be abstracted to this file
 */
import Vue from 'vue';
import { get, post } from 'axios';
import { CoiError } from '../frontEndErrorHandler';

/**
 * Function to perform a generic GET request to the express server, handles basic errors
 * @param {String}  apiUrl       - URL path to use, should be defined on express server
 * @param {Object}  queryParams  - Optional - Object representing url queryString parameters to include with the request
 * @param {Integer} respCode     - Optional - Reponse code expected after get request. Defaults to 200
 * @param {String} responseType  - Optional - Type of data that the server will respond with. Defaults to 'json'
 */
export async function genericGet(apiUrl, queryParams = {}, respCode = 200, responseType = 'json') {
  try {
    Vue.$log.debug(`Sending generic GET request to URL ${apiUrl}. Query params included: ${JSON.stringify(queryParams)}`);

    // apiUrl is required for this function, throw error if not present
    if (!apiUrl) throw new CoiError('apiUrl must be specified to perform get request', 'common.controller.js (genericGet)');

    let response;
    try {
      response = await get(`/api/${apiUrl}`, { params: queryParams, responseType });
    } catch (e) {
      e.message = `Express server error: ${e.response?.data} (${e.message})`;
      const errData = {
        details: 'Error thrown on express server when attempting to perform a GET request',
        data: e.response?.data,
        status: e.response?.status,
        statusText: e.response?.statusText,
        config: {
          data: e.response?.config?.data,
          params: e.response?.config?.params,
          url: e.response?.config?.url,
        },
      };
      throw new CoiError(e, 'common.controller.js (genericGet)', errData);
    }

    // Error checking response
    if (!response || response.status !== respCode || response.data?.error) {
      throw new CoiError(
        `Error sending GET request with params ${JSON.stringify(queryParams)}`,
        'common.controller.js (genericGet)',
        response,
      );
    }

    Vue.$log.debug(`Return data for generic get to URL ${apiUrl}: '${JSON.stringify(response.data)}'`);
    return response.data;
  } catch (e) {
    throw new CoiError(e, 'common.controller.js (genericGet)');
  }
}

/**
 * Function to perform a generic POST request to the express server, handles basic errors
 * @param {String}  apiUrl       - URL path to use, should be defined on express server
 * @param {Object, String}  body - Optional - Object or string to be included in the body of the POST request
 * @param {Object}  queryParams  - Optional - Object representing url queryString parameters to include with the request
 * @param {Integer} respCode     - Optional - Reponse code expected after post request. Defaults to 200
 */
export async function genericPost(apiUrl, body = {}, respCode = 200) {
  try {
    Vue.$log.debug(`Sending generic POST request to URL ${apiUrl}. Body of message: `, body);

    // apiUrl and body are required for this function, throw error if not present
    if (!apiUrl) throw new CoiError('apiUrl must be specified to perform post request', 'common.controller.js (genericPost)');

    let response;
    try {
      response = await post(`/api/${apiUrl}`, body);
    } catch (e) {
      e.message = `Express server error: ${e.response?.data} (${e.message})`;
      const errData = {
        details: 'Error thrown on express server when attempting to perform a POST request',
        data: e.response?.data,
        status: e.response?.status,
        statusText: e.response?.statusText,
        config: {
          data: e.response?.config?.data,
          params: e.response?.config?.params,
          url: e.response?.config?.url,
        },
      };
      throw new CoiError(e, 'common.controller.js (genericPost)', errData);
    }

    // Error checking response
    if (!response || response.status !== respCode || response.data?.error) {
      throw new CoiError(
        `Error sending POST request with body ${JSON.stringify(body)}`,
        'common.controller.js (genericPost)',
        response,
      );
    }

    Vue.$log.debug(`Return data for generic post to URL ${apiUrl}: '${JSON.stringify(response.data)}'`);
    return response.data;
  } catch (e) {
    throw new CoiError(e, 'common.controller.js (genericPost)');
  }
}

/**
 * Function to create different form related objects in the COI system
 * @param {String} objectType          - Type of object to create (passed in to the api URL path)
 * @param {String, Object} body     - Optional - Body to send with create POST request
 */
export async function createObject(objectType, body) {
  try {
    Vue.$log.debug(`Creating a new ${objectType} object. ${(body ? `Message body: ${body}` : 'No message body specified')}`);

    // Object type is required for this function, throw error if not present
    if (!objectType) throw new CoiError('objectType must be specified to create an object', 'common.controller.js (createObject)');

    return await genericPost(`${objectType}/create`, body, 201);
  } catch (e) {
    throw new CoiError(e, 'common.controller.js (createObject)');
  }
}

/**
 * Function to update a specified object in the COI system
 * @param {String} objectType - Type of object to update (passed in to the api URL path)
 * @param {Object} objectData - Object data to send in update request (should include the object ID)
 */
export async function updateObject(objectType, objectData) {
  try {
    Vue.$log.debug(`Updating a ${objectType} object. Object data to send: ${JSON.stringify(objectData)}`);

    // Object type and object update data are both required for this function, throw error if not present
    if (!objectType) throw new CoiError('objectType must be specified to update an object', 'common.controller.js (updateObject)');
    if (!objectData) throw new CoiError('Object data must be passed in to update an object', 'common.controller.js (updateObject)');
    if (!objectData.id) throw new CoiError('Object data must have an ID to update an object', 'common.controller.js (updateObject)');

    return await genericPost(`${objectType}/update`, objectData);
  } catch (e) {
    throw new CoiError(e, 'common.controller.js (updateObject)');
  }
}

/**
 * Function to submit a specified object in the COI system.
 * Note: currently, only disclosure forms can be submitted, however this has been abstracted out to allow future
 * submission of other form types if needed (just need an express server endpoint at /api/<formType>/submit).
 * @param {String} objectType - Type of object to submit (passed in to the api URL path)
 * @param {String} objectId   - ID of object to submit (passed in to the api URL path)
 * @param {Object} objectData - Object data to send in submit request
 */
export async function submitObject(objectType, objectId, objectData) {
  try {
    Vue.$log.debug(`Submitting a ${objectType} object. Object data to send: ${JSON.stringify(objectData)}`);

    // Object type and object data are both required for this function, throw error if not present
    if (!objectType) throw new CoiError('objectType must be specified to submit an object', 'common.controller.js (submitObject)');
    if (!objectId) throw new CoiError('objectId must be specified to submit an object', 'common.controller.js (submitObject)');
    if (!objectData) throw new CoiError('Object data must be passed in to submit an object', 'common.controller.js (submitObject)');

    return await genericPost(`${objectType}/${objectId}/submit`, objectData);
  } catch (e) {
    throw new CoiError(e, 'common.controller.js (submitObject)');
  }
}

/**
 * Function to delete a specified object in the COI system
 * @param {String} objectType - Type of object to delete (passed in to the api URL path)
 * @param {String} objectId   - ID of the object that should be deleted
 */
export async function deleteObject(objectType, objectId) {
  try {
    Vue.$log.debug(`Deleting a ${objectType} object with ID# ${objectId}`);

    // Object type and object ID are both required for this function, throw error if not present
    if (!objectType) throw new CoiError('objectType must be specified to delete an object', 'common.controller.js (deleteObject)');
    if (!objectId) throw new CoiError('objectId must be specified to delete an object', 'common.controller.js (deleteObject)');

    const response = await post(`/api/${objectType}/${objectId}/delete`);
    Vue.$log.debug(`Delete ${objectType} object response: `, JSON.stringify(response));

    // Error checking response
    if (!response || response.status !== 200 || response.data?.error) {
      throw new CoiError(
        `Error deleting ${objectType} object with ID ${objectId} (response code '${response.status}')`,
        'common.controller.js (deleteObject)',
        response,
      );
    }
    return response.data;
  } catch (e) {
    throw new CoiError(e, 'common.controller.js (deleteObject)');
  }
}
