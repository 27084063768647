<template>
  <div id="app" class="d-flex flex-column vh-100">
    <SiteError />
    <SessionTimeout v-if="$store.state.session.loggedIn" />
    <Navbar />

    <!-- Show warning when logged in and using IE -->
    <b-alert v-if="$store.state.env.browser=='ie'" class="my-0" show variant="danger">
      <fa-icon icon="exclamation-triangle" />
      WARNING: This application is not optimized for Internet Explorer.
      You may experience usability issues when using IE; consider switching to
      another browser (e.g., Microsoft Edge, Firefox) when using this app.
    </b-alert>

    <router-view class="d-flex flex-column flex-grow-1" />

    <Footer />
  </div>
</template>

<script>
import SiteError from '@/views/components/site-layout/SiteError.vue';
import SessionTimeout from '@/views/components/site-layout/SessionTimeout.vue';
import Navbar from '@/views/components/site-layout/Navbar.vue';
import Footer from '@/views/components/site-layout/Footer.vue';

export default {
  name: 'app',
  components: {
    SiteError,
    SessionTimeout,
    Navbar,
    Footer,
  },
};
</script>
