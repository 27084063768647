<template>
  <validation-observer ref="pwcobserver" v-slot="{ passes }" disabled>
    <b-form @submit.stop.prevent="passes(changePassword)" id="changepw-form">
      <!-- Current Password Field -->
      <InputField
        v-model="currentPw"
        fieldId="changepw-current-pw"
        fieldName="Current Password"
        fieldLabel="Current Password"
        inputType="password"
        valMode="eager"
        :isReadOnly="!!currentPwProp ? true : null"
        :valRules="{ required: !currentPwProp }"
        :inputAttrs="{ maxlength: '128' }"
      />

      <!-- New Password Field -->
      <InputField
        v-model="newPw"
        fieldId="changepw-new-pw"
        fieldName="New Password"
        fieldLabel="New Password"
        inputType="password"
        valMode="eager"
        :valRules="{
          required: true, min: 8, max: 36, hasupperlower: true, hasnumber: true, hassymbol: true
        }"
        :inputAttrs="{ maxlength: '36' }"
      />

      <!-- New Password Confirm Field -->
      <InputField
        v-model="newPwc"
        fieldId="changepw-new-pwc"
        fieldName="Confirm Password"
        fieldLabel="Confirm New Password"
        inputType="password"
        valMode="eager"
        :valRules="{
          required: true,
          matchfield: {
            target: newPw,
            messageStr: 'Must match Password field above',
          },
        }"
        :inputAttrs="{ maxlength: '36' }"
      />

      <b-alert
        v-model="showChangeError"
        variant="danger"
        id="changepw-errors-alert"
        class="my-3"
        dismissible
      >
        <template v-if="changePwResp && changePwResp.cpwStatus === 'current-pw-incorrect'">
          The current password entered is incorrect, please try again.
        </template>
        <template v-if="changePwResp && changePwResp.cpwStatus === 'new-pw-same'">
          The new password must be different from the current password, please try again.
        </template>
        <template v-if="changePwResp && changePwResp.cpwStatus === 'new-pw-already-used'">
          The new password entered has been used previously. Please select a different password.
        </template>
      </b-alert>
      <div class="clearfix" />

      <b-button
        type="submit"
        :variant="changePwText === 'Password Changed!' ? 'success' : 'primary'"
        id="changepw-button"
        class="float-right"
        :disabled="changePwText === 'Changing...'"
      >
        <b-spinner small type="grow" v-show="changePwText === 'Changing...'" />
        <fa-icon id="form-save-icon-check" icon="check" v-show="changePwText === 'Password Changed!'" class="mr-2" />
        {{ changePwText }}
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import InputField from '@/views/components/form-controls/InputField.vue';
import { CoiError } from '../../../frontEndErrorHandler';
import { genericPost } from '../../../controllers/common.controller';

export default {
  name: 'UserProfilePasswordComp',
  components: {
    ValidationObserver,
    InputField,
  },
  props: {
    userData: { type: Object, required: true },
    currentPwProp: { type: String, default: null },
  },
  data() {
    return {
      changePwText: 'Set Password',
      currentPw: '',
      newPw: '',
      newPwc: '',
      changePwResp: null,
    };
  },
  computed: {
    showChangeError() { return this.changePwResp && this.changePwResp.cpwStatus !== 'password-changed'; },
  },
  methods: {
    /**
     * Function to call when ready to trigger password change (can be called from parent component via refs)
     */
    async changePassword() {
      try {
        this.changePwText = 'Changing...';
        this.changePwResp = await genericPost('user/pwChange', {
          userName: this.userData.userName, currentPw: this.currentPw, newPw: this.newPw,
        });
        this.changePwText = 'Set Password';

        // Emit an event back up the chain, which can be used by the parent component to trigger something
        // when password change is complete, or if an error occurred
        if (this.showChangeError) {
          this.$emit('pwChangeError');
        } else {
          // Refresh user session data after password change, if user is changing their own data
          if (this.$store.state.session.id === this.userData.id) {
            await this.$store.dispatch('refreshSession');
          }
          this.$emit('pwChangeComplete');
          this.newPw = '';
          this.newPwc = '';
          this.currentPw = '';
          this.$refs.pwcobserver.reset();
          this.changePwText = 'Password Changed!';
          setTimeout(() => {
            this.changePwText = 'Set Password';
          }, 4000);
        }
      } catch (e) {
        this.changePwText = 'Set Password';
        throw new CoiError(e, 'UserProfile-Password.vue (changePassword)');
      }
    },
  },
  created() {
    if (this.currentPwProp) this.currentPw = this.currentPwProp;
  },
};
</script>
