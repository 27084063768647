<!--
  Template for showing the COI contact info at different places in the site.
  Per COI4-196, you can now pass in a number between 0 and 1 (defaults to 1) to scale the size of
  the contact block accordingly.
-->
<template>
  <div id="coi-office-contact" class="list-group"
  :style="'transform: scale(' + scale + '); -webkit-transform: scale(' + scale + ');'">
    <b-list-group-item id="coi-office-contact-email">
      <fa-icon icon="at" class="mr-3" />
      <b-link href="mailto:coir@ohsu.edu">coir@ohsu.edu</b-link>
    </b-list-group-item>
    <b-list-group-item id="coi-office-contact-web">
      <fa-icon icon="external-link-alt" class="mr-3" />
      <b-link
        id="coi-office-link"
        href="https://www.ohsu.edu/research-integrity/conflict-interest-coi"
        target="_blank"
      >https://www.ohsu.edu/research-integrity/conflict-interest-coi</b-link>
    </b-list-group-item>
  </div>
</template>

<script>
export default {
  name: 'COIOfficeContact',
  props: {
    scale: {
      type: Number,
      default: 1,
    },
  },
};
</script>
