<template>
  <b-navbar id="coi-footer" type="dark" variant="secondary">
    <b-container fluid>
      <b-navbar-nav>
        <b-nav-item
          id="coi-office-link"
          href="https://www.ohsu.edu/research-integrity/conflict-interest-coi"
          target="_blank"
        >
          Conflict of Interest Office
        </b-nav-item>
        <b-nav-item id="coi-office-email" href="mailto:coir@ohsu.edu">
          coir@ohsu.edu
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-text id="coi-app-version">
          <small v-if="this.$store.state.env.name !== 'production'">
            {{ this.$store.state.env.displayName }} Environment
          </small>
          <br v-if="this.$store.state.env.name !== 'production'
            && this.$store.state.env.name !== 'development'">
          <small v-if="this.$store.state.env.name !== 'development'">
            eCOI version {{ appVersion }}
          </small>
        </b-nav-text>
      </b-navbar-nav>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      nodeEnv: process.env.NODE_ENV,
    };
  },
};
</script>

<style lang="scss">
  #coi-footer {
    z-index: 11;
    .navbar-nav {
      .nav-link, .navbar-text {
        padding: 0.5rem 1rem;
      }
      #coi-office-link .nav-link {
        padding-left: 0;
      }
      #coi-app-version {
        padding: 0 0 0 0.5rem;
      }
    }
  }
</style>
