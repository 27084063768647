<template>
    <b-modal
      id="session-timeout-warning"
      modal-class="session-timeout-modal"
      header-text-variant="danger"
      title="Session Timeout"
      size="md"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      v-model="openTimeoutWarning"
      :static="testMode"
    >
      <p>Your session is about to time out due to inactivity.</p>
      <p>If you do not refresh the session, you will automatically be logged out in {{ secondsRemaining }} seconds.</p>
      <b-button
        id="refresh-session-btn"
        class="float-right ml-2"
        variant="success"
        @click="refreshSession();"
      >
        Refresh Session <fa-icon icon="redo" class="mr-2" />
      </b-button>
      <b-button
        id="logout-btn"
        class="float-right"
        variant="danger"
        @click="logoutAllWindows();"
      >
        Log Out <fa-icon icon="sign-out-alt" class="ml-1" />
      </b-button>
    </b-modal>
</template>

<script>
import { genericGet } from '../../../controllers/common.controller';

export default {
  name: 'SessionTimeout',
  props: {
    testMode: { type: Boolean, default: false },
  },
  data() {
    return {
      warningTimerID: null,
      logoutTimerID: null,
      openTimeoutWarning: false,
      secondsRemaining: 60,
      intervalID: null,
    };
  },
  beforeMount() {
    document.cookie = 'loggedOut=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'timeoutWarned=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    this.startSessionWarningTimer();
    // listen to clicks to check for activity
    window.addEventListener('click', this.startSessionWarningTimer);
  },
  methods: {
    startSessionWarningTimer() {
      // return if already logged out in another window
      if (this.getCookie('loggedOut')) {
        return;
      }

      // clear all timers and warnings
      document.cookie = 'timeoutWarned=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      clearInterval(this.intervalID);
      clearTimeout(this.warningTimerID);
      clearTimeout(this.logoutTimerID);
      this.openTimeoutWarning = false;

      function timeoutWarn() {
        this.warnSessionTimeout();
      }

      genericGet('heartbeat'); // send heartbeat from browser every click

      // 19 minute timer before modal opens with 60 second countdown
      this.warningTimerID = setTimeout(timeoutWarn.bind(this), 19 * 60000);
    },

    warnSessionTimeout() {
      document.cookie = 'timeoutWarned=1; expires=Wed, 31 Dec 2070 12:00:00 UTC; path=/';
      this.secondsRemaining = 60;
      this.openTimeoutWarning = true;

      // countdown discplay for modal: decrement seconds remaining once per second
      this.intervalID = setInterval(() => {
        // if timeoutWarned cookie was cleared in another window, restart this window's timer
        if (!this.getCookie('timeoutWarned')) {
          this.startSessionWarningTimer();

          // if logged out in another window, go to login page in this window
        } else if (this.getCookie('loggedOut')) {
          window.location.href = window.location.origin;
        } else {
          this.secondsRemaining -= 1;
        }
      }, 1000);

      function autoLogout() {
        this.logoutAllWindows();
      }

      // timer for 60 seconds before automatic log out
      this.logoutTimerID = setTimeout(autoLogout.bind(this), 60000);
    },

    logoutAllWindows() {
      // if this is the first window to log out (no loggedOut cookie present) set the loggedOut cookie and use the logout route
      if (!this.getCookie('loggedOut')) {
        document.cookie = 'loggedOut=2; expires=Wed, 31 Dec 2070 12:00:00 UTC; path=/';
        this.$router.push({ name: 'Logout' });
      } else {
        window.location.href = window.location.origin;
      }
    },

    refreshSession() {
      this.startSessionWarningTimer();
    },

    getCookie(cname) {
      const name = `${cname}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalID);
    clearTimeout(this.warningTimerID);
    clearTimeout(this.logoutTimerID);
  },
};
</script>
